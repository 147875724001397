import React from "react";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { KontentRichText } from "../../../types/index";
import { Cta } from "../Cta/Cta";
import { formatLinks } from "../../../lib/links";
import ComponentCTAContainer from "../../blocks/ComponentCTA/index";
import { LinkWithSublinks } from "../../blocks/SidebarLinks/LinkWithSublink";

type Link = {
  codename: string;
  url_slug: string;
  url: string;
  type: string;
  link_id: string;
};

interface Props {
  content: KontentRichText;
  style?: React.CSSProperties;
  classes?: string;
  anchor?: string;
}

interface KontentItemComponent {
  internal: {
    type: string;
  };
  elements: any;
  system?: {
    id: string;
    codename: string;
  };
}

export const RichTextSemanticLinks: React.FC<Props> = ({
  content,
  style,
  classes,
  anchor,
}) => {
  if (!content) {
    return null;
  }

  return (
    <ul id={anchor} className={classes} style={style}>
      <RichTextElement
        value={content?.value}
        linkedItems={content?.modular_content}
        resolveLinkedItem={(linkedItem: KontentItemComponent) => {
          switch (linkedItem?.internal?.type) {
            case "kontent_item_component___cta":
              return (
                <ComponentCTAContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  leftAlign={true}
                  {...linkedItem?.elements}
                />
              );
            case "kontent_item_element___link":
              let cta = formatLinks([linkedItem])[0];

              return (
                <li>
                  <Cta {...cta} />
                </li>
              );
            case "kontent_item_element___link_with_sublinks":
              return (
                <LinkWithSublinks
                  primaryLink={
                    formatLinks(
                      //@ts-ignore
                      linkedItem.elements.primary_link.modular_content
                    )[0]
                  }
                  sublinks={formatLinks(
                    //@ts-ignore
                    linkedItem.elements.sublinks.modular_content
                  )}
                />
              );

            default:
              return;
          }
        }}
      />
    </ul>
  );
};
