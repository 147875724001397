import { RichTextElement } from "@kontent-ai/gatsby-components";
import React from "react";
import { KontentLinkWithSublinks } from ".";
import { formatLinks } from "../../../lib/links";
import { KontentLink, KontentRichText } from "../../../types";
import { LinkWithSublinks } from "./LinkWithSublink";
import * as styles from "./SidebarLinks.module.css";
import { RichTextSemanticLinks } from "../../elements/RichTextSemanticLinks/RichTextSemanticLinks";

interface Props {
  links: {
    value: string;
    modular_content: (KontentLink | KontentLinkWithSublinks)[];
  };
}

export const SidebarLinks: React.FC<Props> = ({ links }) => {
  return (
    <RichTextSemanticLinks
      classes={styles.sidebarLinks}
      //@ts-ignore
      content={links}
    />
  );
};
