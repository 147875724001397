import React, { useContext, useState } from "react";
import { PageMetadataContext } from "../../../lib/PageMetadataContext";
import { PageLink } from "../../../types";
import { ExpandCollapseButton } from "../../elements/ExpandCollapseButton/ExpandCollapseButton";
import * as styles from "./SidebarLinks.module.css";

interface Props {
  primaryLink: PageLink;
  sublinks: PageLink[];
}

export const LinkWithSublinks: React.FC<Props> = ({
  primaryLink,
  sublinks,
}) => {
  const location = useContext(PageMetadataContext).location;
  const currentLocation = location?.pathname;
  const onSubpage = currentLocation?.includes(
    primaryLink.href.split("#main-content")[0]
  );
  const [sublinksOpen, setSublinksOpen] = useState(onSubpage);
  const handleExpandCollapseClick = () => {
    setSublinksOpen(!sublinksOpen);
  };

  return (
    <li>
      <div
        className={`${styles.sidebarLinks} ${sublinksOpen ? styles.open : ""}`}
      >
        <div className={styles.expandable}>
          <a href={primaryLink.href} target={primaryLink.target}>
            {primaryLink.label}
          </a>
          <ExpandCollapseButton
            handleClick={handleExpandCollapseClick}
            open={sublinksOpen}
            children={""}
          ></ExpandCollapseButton>
        </div>
        {sublinksOpen && (
          <ul className={styles.sidebarLinks}>
            {sublinks.map((sublink, idx) => (
              <li key={`link.href${idx}`}>
                <a
                  className={
                    styles.sidebarLink +
                    " " +
                    styles.open +
                    " " +
                    styles.sublink
                  }
                  href={sublink.href}
                  target={sublink.target}
                >
                  {sublink.label}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};
