import { KontentLink, KontentLinks, PageLink } from "../types";

export function getLink(link: KontentLink): string {
  const anchor = link?.elements?.anchor_point?.value
    ? `/#${link?.elements?.anchor_point?.value}`
    : "";

  if (link?.elements?.page?.value[0]?.elements?.url?.url) {
    return link.elements.page.value[0].elements.url?.url + anchor;
  }

  if (link?.elements?.external_url?.value) {
    return link.elements.external_url.value + anchor;
  }

  return "";
}

export function formatLinks(
  links: KontentLinks["modular_content"]
): PageLink[] {
  return links?.map((link) => {
    return {
      id: link?.id,
      label: link?.elements?.label?.value,
      href: getLink(link),
      pageTitle: link?.elements?.page?.value[0]?.elements?.title?.value,
      target:
        link?.elements?.target?.value[0]?.codename === "new_window"
          ? "_blank"
          : "_self",
      ariaLabel: link?.elements?.aria_label?.value,
    };
  });
}
